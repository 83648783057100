<template>
  <div
    :class="explorerClass"
    class="flex-1"
    @dragenter.prevent.stop="onDragEnter"
    @dragover.prevent.stop="onDragOver"
    @dragleave="onDragLeave"
    @drop.prevent.stop="onDrop"
    @contextmenu="onContextMenuView"
  >
    <!-- Upload replace handler -->
    <template v-if="canReplace">
      <input
        id="assets-explorer-direct-replace-upload"
        ref="replaceFileSelector"
        name="assetReplace"
        type="file"
        value="Replace Asset"
        @change="onDirectUpload"
      />
    </template>

    <!-- Main content area: toolbar, browser, status bar -->

    <browser
      :assets="assets"
      :active-folder="activeFolder"
      :multiselected-assets="multiselectedAssets"
      :focused-asset="draggingAssetFocused"
      :can-preview="canPreview"
      :can-select="canSelect"
      :can-replace="canReplace"
      :can-delete="canDelete"
      :can-rename="canRename"
      :can-upload="canUpload"
      :can-see-sidebar="canSeeSidebar"
      :display-mode="displayMode"
      :dragger="activeDragger"
      :project="project"
      :selected-asset="selectedAsset"
      :restricted-action-types="restrictedActionTypes"
      :action-mode="actionMode"
      @delete-asset="onDeleteAsset"
      @preview-asset="onPreviewAsset"
      @rename-asset="onRenameAsset"
      @multiselect-asset="onMultiselectAsset"
      @multideselect-asset="onMultideselectAsset"
      @multiselect-reset="onMultiselectReset"
      @replace-asset="onReplaceAsset"
      @deselect-asset="onDeselectAsset"
      @drag-asset="onDragAsset"
      @drag-asset-focus="onDragAssetFocus"
      @drop-asset="onDropAsset"
      @select-asset="onSelectAsset"
      @view-asset="onViewAsset"
      @new-folder="onNewFolder"
      @move-asset="onMoveAsset"
    />
  </div>
  <!-- /.explorer -->
</template>

<script>
import { VueContext } from 'vue-context'
const Browser = () => import('./assets-explorer/assets-explorer-browser.vue')

const PrevizRenameModal = () => import('@modals/PrevizRename')
const AssetPreviewModal = () => import('@modals/AssetPreview')
const AssetDeleteModal = () => import('@modals/AssetDelete')

function buildDragger() {
  return {
    start(ev, asset) {
      ev.dataTransfer.effectAllowed = 'move'
      ev.dataTransfer.dropEffect = 'move'
      ev.dataTransfer.setData('application/x-previz.asset', asset.id)
      return true
    },
    end(ev) {
      return true
    }
  }
}

export default {
  name: 'AssetsExplorer',

  components: {
    browser: Browser,
    VueContext
  },

  props: {
    assets: {
      required: true,
      type: Array
    },
    activeFolder: {
      required: true,
      type: Object
    },
    embeddedMode: {
      default: false,
      type: Boolean
    },
    overviewMode: {
      default: false,
      type: Boolean
    },
    disablePreview: {
      default: false,
      type: Boolean
    },
    overrideSelect: {
      default: false,
      type: Boolean
    },
    disableUpload: {
      default: false,
      type: Boolean
    },
    dragger: {
      default: null,
      type: Object
    },
    disableDragger: {
      default: false,
      type: Boolean
    },
    initialSelectedAsset: {
      default: null,
      type: Object
    },
    project: {
      required: true,
      type: Object
    },
    actionMode: {
      type: String,
      default: 'view'
    },
    restrictedActionTypes: {
      type: Array,
      default: () => []
    }
  },

  data: function () {
    return {
      isDraggingAsset: false,
      isDraggingUpload: false,
      isUpdatingDragUploadStatus: false,
      selectedAssetId: null,
      replacingAsset: null,
      draggingAssetFocused: null,
      multiselectedAssetsIds: [],
      defaultDragger: null
    }
  },

  computed: {
    echoChannelName() {
      return 'project.' + this.project.id
    },

    contextMenuEnabled() {
      return this.$store.getters['app/getFlag'](
        'projectAssetContextMenuEnabled'
      )
    },

    activeDragger: function () {
      return this.dragger !== null ? this.dragger : this.defaultDragger
    },

    selectedAsset: function () {
      if (this.selectedAssetId === null) return null
      return this.$store.getters['assets/getAssetById'](this.selectedAssetId)
    },

    multiselectedAssets: function () {
      if (this.multiselectedAssetsIds.length < 1) return []
      return this.$store.getters['assets/getAssetsByIds'](
        this.multiselectedAssetsIds
      )
    },

    isEmbeddedMode() {
      return this.embeddedMode
    },

    isOverviewMode() {
      return this.overviewMode
    },

    displayMode() {
      if (this.isEmbeddedMode) return 'mini'
      if (this.isOverviewMode) return 'overview'
      return 'full'
    },

    canSeeSidebar: function () {
      return !this.isEmbeddedMode
    },

    canPreview: function () {
      return !this.disablePreview
    },

    canSelect: function () {
      return !this.overrideSelect
    },

    canUpload: function () {
      return !this.disableUpload
    },

    canDelete: function () {
      return !this.disableUpload
    },

    canReplace: function () {
      return !this.disableUpload
    },

    canRename: function () {
      return !this.disableUpload
    },

    canDragDrop: function () {
      return this.dragger
    },

    canDragUpload: function () {
      return this.canUpload
    },

    explorerClass: function () {
      return {
        'is-dragging': this.isDraggingUpload,
        'show-preview': this.showPreview
      }
    },

    showPreview: function () {
      return this.canPreview && this.selectedAsset !== null
    }
  },

  watch: {
    selectedAssetId: {
      handler: 'fetchAssetInfo'
    },
    activeFolder: {
      handler: 'resetMultiselect'
    }
  },

  mounted() {
    this.attachKeyboardListeners()
    this.registerWebSocketListeners()

    if (this.initialSelectedAsset !== null && this.selectedAssetId === null) {
      this.selectedAssetId = this.initialSelectedAsset.id
    }

    if (this.dragger === null && this.disableDragger !== true) {
      this.defaultDragger = buildDragger()
    }
  },

  beforeDestroy() {
    this.dettachKeyboardListeners()
    this.unregisterWebSocketListeners()
  },

  methods: {
    attachKeyboardListeners() {
      document.addEventListener('keyup', this.handleKeyboardEvents)
    },

    dettachKeyboardListeners() {
      document.removeEventListener('keyup', this.handleKeyboardEvents)
    },

    handleKeyboardEvents(event) {
      let activeElementType = document.activeElement.nodeName

      // We only listen for input if the activeElement is NOT an input, or text area
      if (
        !(activeElementType === 'INPUT' || activeElementType === 'TEXTAREA')
      ) {
        switch (event.code) {
          case 'Space':
            this.onPreviewMultiSelected()
            event.preventDefault()
            event.stopPropagation()
            break
          case 'Enter':
            this.onViewSelected()
            event.preventDefault()
            event.stopPropagation()
            break
        }
      }
    },

    onPreviewMultiSelected() {
      if (this.multiselectedAssets.length > 0) {
        // Just get the first for now
        let asset = this.multiselectedAssets[0]
        if (asset.type !== 'folder') {
          this.onPreviewAsset(this.multiselectedAssets[0])
        }
      }
    },

    onViewSelected() {
      if (this.multiselectedAssets.length > 0) {
        this.onSelectAsset(this.multiselectedAssets[0])
      }
    },

    onContextMenuView(event) {
      // if (this.contextMenuEnabled) {
      //   this.$refs.menu.open()
      //   event.preventDefault()
      // }
    },

    resetMultiselect() {
      this.multiselectedAssetsIds = []
    },

    fetchAssetInfo() {
      if (this.selectedAssetId !== null) {
        this.$store.dispatch('assets/loadAsset', { id: this.selectedAssetId })
      }
    },

    selectFolder(id) {
      this.onSelectAsset({
        id: id,
        type: 'folder'
      })
    },

    /**
     * "Force refresh" handler
     */
    onForceRefresh: function () {
      this.isRefreshing = true

      this.$store
        .dispatch('assets/reload', { project: this.project })
        .then(() => {
          this.isRefreshing = false
        })
        .catch((err) => {
          console.warn(err)
          this.isRefreshing = false
        })
    },

    /**
     * Clear the selected asset property
     */
    deselectAsset: function () {
      this.selectedAssetId = null
    },

    /**
     * "Replace asset" handler
     *
     * @param {Object} asset
     */
    onReplaceAsset: function (asset) {
      this.replacingAsset = asset
      this.$refs.replaceFileSelector.click()
    },

    /**
     * Handle the upload files request
     */
    onDirectUpload: function (event) {
      const input = this.$refs.replaceFileSelector
      const project = this.project
      const asset = this.replacingAsset

      Array.from(input.files).forEach((file) => {
        this.$store.dispatch('assets/upload', {
          file: file,
          project: project,
          parentId: this.activeFolder.id,
          asset: asset
        })
      })

      input.value = ''
    },

    onMultiselectAsset: function (asset) {
      // @todo - disable multiselect for now
      // this.multiselectedAssetsIds.push(asset.id)

      // Make it behave singly for now
      this.multiselectedAssetsIds = []
      this.multiselectedAssetsIds.push(asset.id)
    },

    onMultideselectAsset: function (asset) {
      // @todo - disable multiselect for now
      // this.multiselectedAssetsIds = this.multiselectedAssetsIds.filter(e => e !== asset.id)

      // Make it behave singly for now
      this.multiselectedAssetsIds = []
    },

    onMultiselectReset: function () {
      this.multiselectedAssetsIds = []
    },

    /**
     * "Rename asset" handler
     */
    onDeleteAsset: function (event) {
      this.$modal.show(
        AssetDeleteModal,
        {
          assets: event.assets,
          project: this.project
        },
        {
          height: 'auto',
          width: '400px',
          scrollable: true
        }
      )
    },

    /**
     * "Rename asset" handler
     */
    onPreviewAsset: function (asset) {
      this.$bus.$emit('overlay:modal', {
        component: AssetPreviewModal,
        props: {
          asset: asset
        }
      })
    },

    /**
     * "Rename asset" handler
     */
    onRenameAsset: function (asset) {
      this.$modal.show(
        PrevizRenameModal,
        {
          asset: asset,
          project: this.project
        },
        {
          height: 'auto',
          width: '400px',
          scrollable: true
        }
      )
    },

    /**
     * "Deselect asset" handler
     *
     * @param {Object} asset
     */
    onDeselectAsset: function (asset) {
      this.deselectAsset()
    },

    onDragAssetFocus: function (event) {
      this.draggingAssetFocused = event
    },

    onDragAsset: function () {
      this.isDraggingAsset = true
      this.resetMultiselect() // you can't drag assets while also multi selecting
    },

    onDropAsset: function () {
      this.isDraggingAsset = false
    },

    /**
     * Set isDragging to true
     *
     * Slightly convoluted. If we simply set isDragging to true, the
     * `dragleave` event is immediately triggered. It's unclear whether this
     * is a browser bug, or because of the way Vue re-renders part of the DOM.
     *
     * Either way, the fix is the same.
     */
    onDragEnter: function () {
      if (this.isDraggingAsset) {
        return
      }

      this.isUpdatingDragUploadStatus = true
      this.isDraggingUpload = true

      this.$nextTick(() => {
        this.isUpdatingDragUploadStatus = false
      })
    },

    /**
     * "Drag leave" handler
     */
    onDragLeave: function () {
      if (this.isDraggingAsset) {
        return
      }

      if (!this.isUpdatingDragUploadStatus) {
        this.isDraggingUpload = false
      }
    },

    /**
     * Does nothing; we just need to prevent the default action
     */
    onDragOver: function (event) {
      // console.log('ON DRAG OVER EVENT', event.target)
      // $(event.target).attr("drop-active", true);
    },

    /**
     * "Drop" handler
     *
     * @param {Event} event
     */
    onDrop: function (event) {
      const project = this.project
      const activeFolder = this.activeFolder.id

      if (this.isDraggingAsset) {
        if (this.draggingAssetFocused !== null) {
          let id = event.dataTransfer.getData('application/x-previz.asset')

          this.onMoveAsset({
            assets: [{ id: id }],
            folderId: this.draggingAssetFocused.id
          })

          this.draggingAssetFocused = null
        }
      } else {
        this.isUpdatingDragUploadStatus = false
        this.isDraggingUpload = false

        const upload = (file, path) => {
          if (file.name.startsWith('.')) return
          let extra = {
            relativePath: path
          }
          this.$store.dispatch('assets/upload', {
            file: file,
            project: project,
            parentId: activeFolder,
            extra
          })
        }

        const traverse = (item, path) => {
          path = path || ''

          if (item.isFile) {
            item.file((file) => {
              upload(file, path)
            })
          }

          if (item.isDirectory) {
            let dirReader = item.createReader()
            dirReader.readEntries(function (entries) {
              Array.from(entries).forEach((child) => {
                traverse(child, path + item.name + '/')
              })
            })
          }
        }

        Array.from(event.dataTransfer.items).forEach((item) => {
          item = item.webkitGetAsEntry()
          if (item) {
            traverse(item)
          }
        })
      }
    },

    /**
     * "Process asset" handler
     *
     * @param {Object} asset
     */
    onProcessAsset: function (asset) {
      this.$store
        .dispatch('processAsset', { asset: asset })
        .then((response) => {
          alert.success('Asset Process job created')
        })
    },

    /**
     * "Select asset" handler
     *
     * @param {Object} asset
     */
    onSelectAsset: function (object) {
      // We allow the explorer to embedded elsewhere.
      // In this case a component can pass through their own overridding handler
      // for the onSelectAsset function. If we're not in the default actionMode view
      // and the item is not a folder we pass the event upwards

      let mode = 'view'
      let asset = null

      if (object.asset !== undefined) asset = object.asset
      else asset = object

      if (object.mode !== undefined) {
        if (object.mode === 'edit') mode = 'edit'
      }

      if (this.canSelect) {
        if (asset.type === 'root') {
          this.$router.push('/p/' + this.project.slug + '/content')
        } else if (asset.type === 'folder') {
          this.$router.push({
            name: 'content_folder',
            params: { uuid: asset.id }
          })
        } else {
          if (
            (asset.type === 'scene' ||
              asset.type === 'sequence' ||
              asset.type === 'composition') &&
            mode === 'edit'
          ) {
            if (asset.type === 'sequence' && asset.depends_on_id !== null) {
              // Sequences actually go to the parent scene editor
              this.$router.push({
                name: 'content_edit',
                params: { uuid: asset.depends_on_id },
                query: { sequence: asset.id }
              })
            } else {
              this.$router.push({
                name: 'content_edit',
                params: { uuid: asset.id }
              })
            }
          } else {
            this.$router.push({
              name: 'content_view',
              params: { uuid: asset.id }
            })
          }
        }
      } else {
        if (asset.type === 'root') {
          this.$store.dispatch('assets/loadRootFolder', {
            project: this.project
          })
        } else if (asset.type === 'folder') {
          this.$store.dispatch('assets/loadFolder', { folderId: asset.id })
        } else {
          // Just emit the event upwards
          this.$emit('select-asset', asset)
        }
      }
    },

    /**
     * "Move Asset" handler
     *
     * @param {Object} event
     */
    onMoveAsset: function (event) {
      if (event.hasOwnProperty('folderId')) {
        this.$store.dispatch('assets/move', event)
      }

      if (event.hasOwnProperty('folders')) {
        this.$bus.$emit('modal:show', {
          type: 'asset-move-modal',
          modalProps: {
            assets: event.assets,
            folders: event.folders
          }
        })
      }
    },

    /**
     * "New Folder" handler
     *
     * @param {Object} asset
     */
    onNewFolder: function () {
      let parent = this.$store.getters['assets/active']

      this.$bus.$emit('modal:show', {
        type: 'asset-new-folder-modal',
        modalProps: {
          project: this.project,
          parent: parent
        }
      })
    },

    /**
     * "View asset" handler
     *
     * @param {Object} asset
     */
    onViewAsset: function (asset) {
      window.open(asset.url, '_blank')
    },

    registerWebSocketListeners: function () {
      if (window.Echo) {
        window.Echo.private(this.echoChannelName).listen(
          '.assetBrowserUpdated',
          (event) => {
            this.$store.dispatch('assets/addItem', { item: event.asset })
          }
        )
      }
    },

    unregisterWebSocketListeners: function () {
      if (window.Echo) {
        window.Echo.leave(this.echoChannelName)
      }
    }
  }
}
</script>

<style scoped>
input[type='file'] {
  display: none;
}
</style>
